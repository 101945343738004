.home {
    display: flex;

    .homeContainer {
        // background-color: lightblue;
        flex: 6;

        .widgets, .charts {
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .charts {
            padding: 5px 20px;
        }
    }
}

