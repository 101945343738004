.navbar {
    // background-color: aqua;
    height: 50px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    align-items: center;
    display: flex;
    font-size: 14px;
    color: #555;

    .wrapper {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: rebeccapurple;

        .search {
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;

            input{
                border: none;
                outline: none;
                background: transparent;

                &::placeholder{
                    font-size: 12px;
                }
            }
        }

        .items {
            display: flex;
            align-items: center;

            .item {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin: 20px;
                position: relative;

                &:hover{
                    // background-color: purple;
                }
            }

            .icon {
                // color: white;
                font-size: 20px;
            }

            .avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            .counter {
                height: 15px;
                width: 15px;
                background-color: red;
                border-radius: 50%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                font-weight: bold;
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
    }
}